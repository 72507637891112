import {
  VpButton,
  VpInput,
  VpIcon,
  VpFormControl,
  VpCheckbox,
  VpIconButton,
} from "@vtmn-play/react";
import React, { useEffect, useState } from "react";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import { QrScanner } from "@yudiel/react-qr-scanner";

import { FormattedMessage, useIntl } from "react-intl";
import {
  resetSavedResponses,
  setAccountCreationRefused,
  setInspectionInfo,
  setItemInfo,
} from "../../redux/slices/PDISlice";
import useDebounce from "../../../../Redux/Hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import {
  extractOrderItems,
  isAlphanumeric,
} from "../../../../Common/utils/helpers";
import { getSerialNumberFromAPIC } from "../../../../Common/APIs/getOrderInfo";
const PDIInspectionInfo = (props) => {
  const [scannerConfig, setScannerConfig] = useState({
    isOpen: false,
    scanReference: undefined,
  });
  const [orderItems, setOrderItems] = useState([]);
  const [loading, setLoading] = useState({
    orderID: false,
    serialNumber: false,
  });
  const [mounted, setMounted] = useState(false); // Flag to track component mount status
  const [failedAPIs, setFailedAPIs] = useState({
    orderAPI: false,
    serialNumberAPI: false,
  });
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const intl = useIntl();
  const {
    itemInfo: {
      modelId,
      modelName,
      orderReferenceNumber,
      serialNumber,
      loyaltyCard,
    },
    inspectionInfo: { purchaseChannel, productCategory },
    accountCreationRefused,
  } = useSelector((state) => state.pdi);

  // Debounced OrderID Input
  const debouncedOrderID = useDebounce(orderReferenceNumber, 1500);
  useEffect(() => {
    async function fetchData() {
      setLoading((prevState) => ({ ...prevState, orderID: true }));
      setFailedAPIs((prevState) => ({ ...prevState, orderAPI: false }));
      const { reference_id, filtered_items } =
        await extractOrderItems(debouncedOrderID);

      setLoading((prevState) => ({ ...prevState, orderID: false }));
      dispatch(
        setItemInfo({
          orderNumber: reference_id,
          orderReferenceNumber: debouncedOrderID,
        })
      );

      if (filtered_items?.length === 0) {
        setFailedAPIs((prevState) => ({ ...prevState, orderAPI: true }));
      } else if (filtered_items?.length === 1) {
        populateFields(filtered_items[0]);
      } else if (filtered_items?.length > 1) {
        setOrderItems(filtered_items);
      }
    }
    if (mounted) {
      if (debouncedOrderID && debouncedOrderID?.length > 11) {
        fetchData();
      }
    } else {
      setMounted(true); // Set mounted flag to true after the first render
    }
  }, [debouncedOrderID]);

  // Debounced APIC Input
  const debouncedAPIC = useDebounce(serialNumber, 1500);
  useEffect(() => {
    async function fetchData() {
      setLoading((prevState) => ({ ...prevState, serialNumber: true }));
      setFailedAPIs((prevState) => ({ ...prevState, serialNumberAPI: false }));
      const { serialNumber } = await getSerialNumberFromAPIC(debouncedAPIC);
      if (!serialNumber)
        setFailedAPIs((prevState) => ({ ...prevState, serialNumberAPI: true }));
      setLoading((prevState) => ({ ...prevState, serialNumber: false }));
      dispatch(
        setItemInfo({
          serialNumber:
            serialNumber?.toString()?.padStart(12, "0") || debouncedAPIC,
        })
      );
    }
    if (mounted) {
      // The APIC should be alphanumeric and of lenght 10.Only then make APIC api call to get serial number. Else, ignore.
      if (
        debouncedAPIC &&
        debouncedAPIC?.length === 10 &&
        isAlphanumeric(debouncedAPIC)
      ) {
        fetchData();
      }
    } else {
      setMounted(true); // Set mounted flag to true after the first render
    }
  }, [debouncedAPIC]);

  const handleOrderIdChange = (e) => {
    let orderIDComponents = e?.target?.value?.trim()?.split(":");
    dispatch(
      setItemInfo({
        orderReferenceNumber: orderIDComponents[orderIDComponents.length - 1],
      })
    );
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let processedValue;

    // Trim/validate data based on condition.
    switch (name) {
      case "modelId":
        processedValue = value?.replace(/[^0-9]/g, "");
        break;
      case "serialNumber":
        setFailedAPIs((prevState) => ({
          ...prevState,
          serialNumberAPI: false,
        }));
        // Remove space between copy pasted values
        processedValue = value?.replace(/[^a-zA-Z0-9]/g, "")?.trim();
        break;
      default:
        processedValue = value;
        break;
    }
    dispatch(
      setItemInfo({
        [name]: processedValue,
      })
    );
  };

  const populateFields = (filtered_items) => {
    const isEbike =
      /(electric(al|als)?|e[- ]?bike)/i.test(filtered_items?.title) && // Matches "electric", "electrical", "electricals", "ebike", "e-bike" (case-insensitive)
      filtered_items?.technicalInfos?.some((item) =>
        /battery/i.test(item?.name)
      );

    dispatch(
      setInspectionInfo({
        productCategory: isEbike ? "e-bike" : "bike",
      })
    );
    dispatch(resetSavedResponses());
    dispatch(
      setItemInfo({
        modelName: filtered_items?.title,
        modelId: filtered_items?.modelId,
      })
    );
  };

  const handleScanDecode = (data) => {
    if (scannerConfig?.scanReference === "orderNumber") {
      let orderIDComponents = data?.trim()?.split(":");
      dispatch(
        setItemInfo({
          orderReferenceNumber: orderIDComponents[orderIDComponents.length - 1],
        })
      );
    } else if (scannerConfig?.scanReference === "serialNumber") {
      dispatch(
        setItemInfo({
          serialNumber: data
            ?.toString()
            ?.replace(/[^a-zA-Z0-9]/g, "")
            ?.trim(),
        })
      );
    }

    setScannerConfig({
      isOpen: false,
      scanReference: undefined,
    });
  };

  return (
    <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
      <h1 className="vtmn-font-bold vtmn-text-2xl">
        {purchaseChannel === "ONLINE_ORDER" ? (
          <FormattedMessage id="pdi.form.product-detail.scan.title" />
        ) : (
          <FormattedMessage id="pdi.form.product-detail.title" />
        )}
      </h1>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {purchaseChannel === "ONLINE_ORDER" && (
          <div className="vtmn-relative vtmn-mt-5">
            <VpFormControl>
              <VpFormControl.Label>
                <FormattedMessage id="pdi.form.product-detail.order-number" />
              </VpFormControl.Label>
              <VpInput
                className={`vtmn-w-full`}
                endSlot={
                  loading?.orderID && orderReferenceNumber ? (
                    <VpIconButton
                      size={"small"}
                      type={"button"}
                      loading={true}
                      disabled={true}
                      variant={"tertiary"}
                    ></VpIconButton>
                  ) : (
                    <button
                      aria-label="icon to scan."
                      onClick={(e) => {
                        setScannerConfig({
                          isOpen: true,
                          scanReference: "orderNumber",
                        });
                      }}
                      type="button"
                    >
                      <VpIcon name="barcode-scan" />
                    </button>
                  )
                }
                onChange={handleOrderIdChange}
                placeholder="eg: GB..."
                value={orderReferenceNumber}
                name="orderNumber"
              />
              <VpFormControl.Helper>
                {loading?.orderID && orderReferenceNumber && (
                  <FormattedMessage id="pdi.inspection.scan.loading-order-info-loading" />
                )}
              </VpFormControl.Helper>
            </VpFormControl>
          </div>
        )}

        {purchaseChannel === "ONLINE_ORDER" && Boolean(orderItems?.length) && (
          <div className="order-info-overlay">
            <div className="order-info-popup">
              <h2 className="vtmn-text-2xl vtmn-font-bold vtmn-text-center vtmn-flex vtmn-items-center">
                <span className="vtmn-flex-1">
                  <FormattedMessage id="pdi.form.order-detail.select-item" />
                </span>
                <span
                  className="vtmx-close-circle-line vtmn-z-30 vtmn-w-min vtmn-text-4xl vtmn-cursor-pointer"
                  onClick={() => {
                    setOrderItems([]);
                  }}
                ></span>
              </h2>

              {orderItems?.map((item, index) => (
                <div className="block" key={index}>
                  <div
                    className="vtmn-card vtmn-card_variant--side-image vtmn-my-2 vtmn-border vtmn-border-grey-light-3 vtmn-cursor-pointer"
                    onClick={() => {
                      populateFields(item);
                      setOrderItems([]);
                    }}
                  >
                    <div className="vtmn-card_image vtmn-self-center ">
                      <img alt="product-img" src={item?.mainImage?.url} />
                    </div>
                    <div className="vtmn-card_content">
                      <span className="vtmn-card_content--body">
                        <div className="vtmn-flex vtmn-flex-col vtmn-text-xs vtmn-font-bold">
                          <span>
                            <FormattedMessage id="pdi.form.order-detail.select-item.name" />
                            : <b>{item?.title.slice(0, 20) + "..."}</b>
                          </span>
                          <span>
                            <FormattedMessage id="pdi.form.order-detail.select-item.brand" />
                            : <b>{item?.brand?.name}</b>
                          </span>
                          <span>
                            <FormattedMessage id="pdi.form.order-detail.select-item.size" />
                            : <b>{item?.sizeLabel}</b>
                          </span>
                          <span>
                            <FormattedMessage id="pdi.form.order-detail.select-item.model-id" />
                            : <b> {item?.modelId}</b>
                          </span>
                        </div>
                      </span>
                      <button className="vtmn-btn vtmn-btn_size--small vtmn-btn_variant--primary">
                        <FormattedMessage id="pdi.form.order-detail.select-item.select-item" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="vtmn-relative vtmn-mt-5">
          <VpFormControl required>
            <VpFormControl.Label>
              <FormattedMessage id="pdi.form.product-detail.item-code" />
            </VpFormControl.Label>
            <VpInput
              // When you set inputmode="numeric", it tells browsers, especially on mobile devices, to display a numeric keypad instead of the standard keyboard
              inputmode="numeric"
              type="number"
              pattern="[0-9 ]*"
              className={`vtmn-w-full`}
              onChange={handleInputChange}
              placeholder="eg: 2126071"
              value={modelId}
              name="modelId"
              disabled={loading?.orderID}
            />
          </VpFormControl>
        </div>
        {
          // (purchaseChannel === "PHYSICAL_INSPECTION" || failedAPIs?.orderAPI) &&
          <div className="vtmn-relative ">
            <VpFormControl className="vtmn-my-1">
              <VpCheckbox
                id="ebike-checkbox"
                checked={productCategory === "e-bike"}
                onChange={(event) => {
                  const { checked } = event.target;
                  dispatch(
                    setInspectionInfo({
                      productCategory: checked ? "e-bike" : "bike",
                    })
                  );
                  dispatch(resetSavedResponses());
                }}
              >
                <FormattedMessage id="pdi.form.inspection.model.is_ebike" />
              </VpCheckbox>
            </VpFormControl>
          </div>
        }

        <div className="vtmn-relative vtmn-mt-5">
          <VpFormControl required>
            <VpFormControl.Label for="model-name">
              <FormattedMessage id="pdi.form.product-detail.model-name" />
            </VpFormControl.Label>
            <VpInput
              className={`vtmn-w-full`}
              onChange={handleInputChange}
              placeholder="eg: Van Rysel RCR Pro"
              value={modelName}
              name="modelName"
              disabled={loading?.orderID}
            />
          </VpFormControl>
        </div>

        <div className="vtmn-relative vtmn-mt-5">
          <VpFormControl required>
            <VpFormControl.Label for="serial-number">
              <FormattedMessage id="pdi.form.product-detail.serial-number" />
            </VpFormControl.Label>
            <VpInput
              className={`vtmn-w-full`}
              onChange={handleInputChange}
              placeholder="eg: 000..."
              value={serialNumber}
              name="serialNumber"
              endSlot={
                loading?.serialNumber && serialNumber ? (
                  <VpIconButton
                    size={"small"}
                    type={"button"}
                    loading={true}
                    disabled={true}
                    variant={"tertiary"}
                  ></VpIconButton>
                ) : (
                  <button
                    aria-label="icon to scan."
                    onClick={(e) => {
                      setScannerConfig({
                        isOpen: true,
                        scanReference: "serialNumber",
                      });
                    }}
                    type="button"
                  >
                    <VpIcon name="barcode-scan" />
                  </button>
                )
              }
            />
            <VpFormControl.Helper>
              {loading?.serialNumber && serialNumber && (
                <FormattedMessage id="pdi.inspection.scan.loading-serial-number-info-loading" />
              )}
              <span className="vtmn-text-background-alert">
                {serialNumber?.length === 10 &&
                  !loading?.serialNumber &&
                  failedAPIs?.serialNumberAPI && (
                    <FormattedMessage id="pdi.inspection.scan.wrong-apic" />
                  )}
                {isAlphanumeric(serialNumber) && serialNumber?.length > 10 && (
                  <FormattedMessage id="pdi.inspection.scan.loading-serial-number-warning" />
                )}
              </span>
            </VpFormControl.Helper>
          </VpFormControl>
        </div>
        {purchaseChannel === "PHYSICAL_INSPECTION" && (
          <div className="vtmn-relative vtmn-mt-5">
            <VpFormControl>
              <VpFormControl.Label for="membership-number">
                <FormattedMessage id="pdi.form.product-detail.membership-number" />
              </VpFormControl.Label>
              <VpInput
                className={`vtmn-w-full  `}
                onChange={handleInputChange}
                placeholder="eg: 2098..."
                value={loyaltyCard}
                name="loyaltyCard"
                disabled={accountCreationRefused}
              />
            </VpFormControl>
            <VpFormControl className="vtmn-my-1">
              <VpCheckbox
                id="customer-refused"
                checked={accountCreationRefused}
                onChange={(event) => {
                  const isChecked = event?.target?.checked;
                  dispatch(setAccountCreationRefused(isChecked));
                  // If customer refused to create account, then set loyaltyCard number to Refused. Else null
                  dispatch(
                    setItemInfo({ loyaltyCard: isChecked ? "Refused" : "" })
                  );
                }}
              >
                <FormattedMessage id="pdi.form.inspection.loyalty_card.customer_refused" />
              </VpCheckbox>
            </VpFormControl>
          </div>
        )}
        <div className="vtmn-py-2 vtmn-mt-5">
          <VpButton
            className="vp-button vtmn-btn_size--stretched"
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              navigate("questions", { replace: false });
            }}
            disabled={
              !modelName?.trim() ||
              !modelId?.trim() ||
              !serialNumber?.trim() ||
              isAlphanumeric(serialNumber) ||
              loading?.orderID ||
              loading?.serialNumber
            }
          >
            <FormattedMessage id="pdi.nav.submit" />
          </VpButton>
        </div>
      </form>
      {(purchaseChannel === "ONLINE_ORDER" ||
        purchaseChannel === "PHYSICAL_INSPECTION") &&
        scannerConfig?.isOpen && (
          <div className="scanner-overlay">
            <span
              className="vtmx-close-circle-line vtmn-z-30 vtmn-w-min vtmn-text-4xl close-btn vtmn-text-white "
              onClick={() => {
                setScannerConfig({
                  isOpen: false,
                  scanReference: undefined,
                });
              }}
            ></span>
            <QrScanner
              scanDelay={300}
              onDecode={(data) => {
                handleScanDecode(data);
              }}
              onError={(error) => {
                setScannerConfig({
                  isOpen: false,
                  scanReference: undefined,
                });
                alert(intl.formatMessage({ id: "pdi.inspection.scan.error" }));
              }}
              containerStyle={{
                background: "black",
                position: "absolute",
                height: "100%",
                width: "100%",
                paddingTop: "0%",
              }}
              stopDecoding={!scannerConfig?.isOpen}
            />
            <div className="vtmn-font-bold vtmn-my-3 vtmn-relative vtmn-text-center vtmn-text-lg vtmn-text-white vtmn-w-3/4 vtmn-m-auto">
              <FormattedMessage id="pdi.inspection.scan.warning"></FormattedMessage>
            </div>
          </div>
        )}
    </div>
  );
};

export default PDIInspectionInfo;
